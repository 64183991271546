import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Swiper from "../components/swiper/swiper"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
// import Modal from "../components/modal/modal"
import Form from "../components/form/form"

import AboutUsIMG from "../images/circle-produce.png"
import OurMissionIMG from "../images/circle-grocery.png"
import DiscountDealIMG from "../images/circle-grocers.png"

import FreihoferBrand from "../images/freihofer.png"
import HormelBrand from "../images/hormel-small.png"
import NestleBrand from "../images/nestle-small.png"
import KraftBrand from "../images/kraft-small.png"

import TestimonialIMG from "../images/2.jpg"

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div className="container-fluid hero">
					<div className="row">
						<div className="col">
							<div className="text-center">
								<h4
									data-aos="fade"
									data-aos-duration="600"
									data-aos-delay="600"
									className="text-green"
								>
									YOUR LOCAL AND LOYAL, NEIGHBOURHOOD
								</h4>
								<h1
									data-aos="fade-down"
									data-aos-duration="600"
									data-aos-delay="1000"
									className="text-yellow"
								>
									Discount Grocery Store
								</h1>
								<Link
									className="btn btn-primary btn-xl rounded-pill mt-4"
									to="/"
								>
									Learn More
								</Link>
							</div>
						</div>
					</div>
				</div>
				<section id="about">
					<div className="about-us pt-5 mb-5">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6 col-sm-12">
									<div className="p-5">
										<h5 className="text-yellow about-us-subheader">ABOUT US</h5>
										<h1 className="text-green about-us-header">
											The Helping Hand aims...
										</h1>
										<h5 className="about-us-para text-black-50">
											To provide the community with fresh produce, and valuable
											food at an affordable price!
										</h5>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12">
									<div className="p-5">
										<img
											className="rounded-circle img-fluid"
											src={AboutUsIMG}
											alt="About Us"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="mission">
					<div className="our-mission bg-lgreen">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6 col-sm-12">
									<div className="p-5">
										<img
											className="rounded-circle img-fluid"
											src={OurMissionIMG}
											alt="Our Mission"
										/>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12">
									<div className="p-4 text-center">
										<h5 className="text-white about-us-subheader">
											OUR MISSION
										</h5>
										<h1 className="text-black-50 about-us-header">
											We envision that...
										</h1>
										<p className="about-us-para text-black-50">
											This community space will service the neighbourhood,
											cultivate, leadership, and build up community! Don't
											hesitate to Contact Us!
										</p>
									</div>
									<div className="contact-clean">
										<Form
											form_name="Newsletter Form"
											form_id="5e4b952361380e3eb8d8e90d"
											form_classname="form-newsletter"
										>
											<div className="bg-yellow">
												<div className="form-group mt-0">
													<input
														className="form-control yellow"
														type="text"
														name="Name"
														id="name"
														required=""
													/>
													<label htmlFor="name">Name</label>
												</div>
												<div className="form-group">
													<input
														className="form-control active"
														type="email"
														name="Email Address"
														id="email"
														required
													/>
													<label htmlFor="email">Email</label>
												</div>
												<div className="form-group">
													<input
														className="form-control active"
														type="text"
														name="Subject"
														id="subject"
														required
													/>
													<label htmlFor="subject">Subject</label>
												</div>
												<div className="form-group">
													<textarea
														className="form-control active h-auto"
														required
													/>
													<label>Message</label>
												</div>
												<div className="form-group">
													<div className="centered-captcha mb-4 mx-auto">
														<div className="webriq-recaptcha" />
													</div>
												</div>
												<div className="form-group text-center">
													<button
														className="btn btn-primary btn-xl rounded-pill mt-4"
														type="submit"
													>
														Learn More
													</button>
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="discounts">
					<div className="discounts-and-deals">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6 col-sm-12">
									<div className="p-5">
										<h5 className="text-yellow about-us-subheader">
											DISCOUNTS AND DEALS
										</h5>
										<h1 className="text-green about-us-header">
											Let there be discounts and deals!
										</h1>
										<h5 className="about-us-para text-black-50">
											Follow the page for updates, or call us to find out all
											the great things happening with your neighbourhood
											discount grocery store.
										</h5>
										<div className="social-icons mt-3">
											<a
												href="https://www.facebook.com/thehelpinghandneighborhoodgrocerystore"
												target="blank_"
												rel="noopener noreferrer"
											>
												<i className="fa fa-facebook-f"></i>
											</a>
											<a href="tel:719-358-8854">
												<i className="fa fa-phone"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12">
									<div className="p-5">
										<img
											className="rounded-circle img-fluid"
											src={DiscountDealIMG}
											alt="Discounts & Deals"
										/>
									</div>
								</div>
							</div>
							<div className="row align-items-center mb-0">
								<div className="col-12 text-center">
									<h3 className="text-yellow">WE OFFER THESE TRUSTED BANDS</h3>
								</div>
							</div>
							<div className="row text-center align-items-center mt-2">
								<div className="col-lg-3 col-md-3 col-sm-6">
									<img
										className="img-fluid"
										src={FreihoferBrand}
										alt="Freihofer's Bakery"
									/>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-6">
									<img
										className="img-fluid"
										src={HormelBrand}
										alt="Hormel Foods"
									/>
								</div>
								<div className="col-lg-3 col-md-3 col-sm-6">
									<img className="img-fluid" src={NestleBrand} alt="Nestle" />
								</div>
								<div className="col-lg-3 col-md-3 col-sm-6">
									<img className="img-fluid" src={KraftBrand} alt="Kraft" />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="testiments">
					<div className="testimonials">
						<div className="container py-5">
							<div className="row">
								<div className="col-12">
									<div className="text-center">
										<h3 className="text-yellow">TESTIMONIALS</h3>
										<h1 className="text-lgreen">
											What our customers have been saying
										</h1>
									</div>
								</div>
							</div>
							<div className="row">
								<Swiper slidesperview={1}>
									<div className="w-100">
										<div className="row justify-content-center">
											<div className="col-8 mt-5 review-container bg-yellow">
												<img
													className="review-image"
													src={TestimonialIMG}
													alt="Testimonial"
												/>
												<h4>Melanie</h4>
												<p>
													Super amazing and truly seems to care about the
													community. Great produce and a way better price than
													big chain markets.
												</p>
											</div>
										</div>
									</div>
									<div className="w-100">
										{" "}
										<div className="row justify-content-center">
											<div className="col-8 mt-5 review-container bg-yellow">
												<img
													className="review-image"
													src={TestimonialIMG}
													alt="Testimonial"
												/>
												<h4>Melanie</h4>
												<p>
													Super amazing and truly seems to care about the
													community. Great produce and a way better price than
													big chain markets.
												</p>
											</div>
										</div>
									</div>
									<div className="w-100">
										<div className="row justify-content-center">
											<div className="col-8 mt-5 review-container bg-yellow">
												<img
													className="review-image"
													src={TestimonialIMG}
													alt="Testimonial"
												/>
												<h4>Melanie</h4>
												<p>
													Super amazing and truly seems to care about the
													community. Great produce and a way better price than
													big chain markets.
												</p>
											</div>
										</div>
									</div>
								</Swiper>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
